div.calendarDate {
    font-size:0.3em; 
    /* change calendar size */
    display: block;
    position: relative;
    /* width: 7em;*/
     /* height: 7em; */
    background-color: #fff;
    border-radius: 0.7em;
    -moz-border-radius: 0.7em;
    box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 0 0 1px #bdbdbd;
    overflow: hidden;
}
div.calendarDate * {
    display: block;
    width: 100%;
    font-size: 1em;
    font-weight: bold;
    font-style: normal;
    text-align: center;
}
/* Month - Dec */
div.calendarDate strong {
    position: absolute;
    top: 0;
    font-size: 1.8em;
    color: #fff;
    background-color: #434444;
    box-shadow: 0 2px 0 #19aaaa;
}
/* Day - 24 */
div.calendarDate span {
    font-size: 3.5em;
    letter-spacing: -0.05em;
    padding-top: 0.7em;
    color: #2f2f2f;
}